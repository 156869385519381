import React, { Fragment } from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import ProjectHeader from '../components/ProjectHeader'
import ProjectDescription from '../components/ProjectDescription'
import ProjectComingSoon from '../components/ProjectComingSoon'
import ProjectNextLink from '../components/ProjectNextLink'
import InstagramFeed from '../components/InstagramFeed'
import ContactBoxes from '../components/ContactBoxes'
import Footer from '../components/Footer'

import headerRightBanner from '../images/projects/apartamento-103-nw/header-right-banner.jpg'

import '../styles/global.scss'

const dynamicInfos = {
  headerImage: headerRightBanner,
  title: 'Apartamento',
  subTitle: '103nw',
  typeService: 'PROJETO + OBRA',
  duration: '15 meses',
  deliverDate: '2019',
  local: 'Brasília',
  color: '#fe8983',
  description: (
    <Fragment>
      <p>
        Projeto de reforma de apartamento entregue pela construtora. Fizemos
        nesse apartamento de 2 quartos, poucas intervenções de layout, entramos
        com <b>muita marcenaria e decoração</b> para que a cliente e seu filho
        adolescente pudessem realizar o sonho do cantinho só deles.
      </p>
      <p>
        Cada um com sua personalidade tem seus espaços definidos em um projeto
        com boas pitadas de ousadia e humor. A{' '}
        <b>iluminação desse projeto tem papel fundamental</b> para o resultado
        que quisemos alcançar.
      </p>
    </Fragment>
  ),
  nextProject: 'cozinha-28-n'
}

const IndexPage = () => (
  <Layout>
    <SEO
      title="Apartamento 103 NW"
      keywords={[`gatsby`, `application`, `react`]}
    />
    <ProjectHeader
      headerImage={dynamicInfos.headerImage}
      title={dynamicInfos.title}
      subTitle={dynamicInfos.subTitle}
    />
    <ProjectDescription
      typeService={dynamicInfos.typeService}
      duration={dynamicInfos.duration}
      deliverDate={dynamicInfos.deliverDate}
      local={dynamicInfos.local}
      description={dynamicInfos.description}
      color={dynamicInfos.color}
    />
    <ProjectComingSoon color={dynamicInfos.color} />
    <ProjectNextLink nextProject={dynamicInfos.nextProject} />
    <InstagramFeed />
    <ContactBoxes />
    <Footer />
  </Layout>
)

export default IndexPage
